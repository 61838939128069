// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dh_zc";
export var servicesToolkitSection__card = "dh_zd";
export var servicesToolkitSection__icons = "dh_zf";
export var servicesToolkitSection__serviceToolkitIcon1 = "dh_zh";
export var servicesToolkitSection__serviceToolkitIcon10 = "dh_zs";
export var servicesToolkitSection__serviceToolkitIcon11 = "dh_zt";
export var servicesToolkitSection__serviceToolkitIcon12 = "dh_zv";
export var servicesToolkitSection__serviceToolkitIcon13 = "dh_zw";
export var servicesToolkitSection__serviceToolkitIcon14 = "dh_zx";
export var servicesToolkitSection__serviceToolkitIcon15 = "dh_zy";
export var servicesToolkitSection__serviceToolkitIcon16 = "dh_zz";
export var servicesToolkitSection__serviceToolkitIcon17 = "dh_zB";
export var servicesToolkitSection__serviceToolkitIcon18 = "dh_zC";
export var servicesToolkitSection__serviceToolkitIcon19 = "dh_zD";
export var servicesToolkitSection__serviceToolkitIcon2 = "dh_zj";
export var servicesToolkitSection__serviceToolkitIcon20 = "dh_zF";
export var servicesToolkitSection__serviceToolkitIcon3 = "dh_zk";
export var servicesToolkitSection__serviceToolkitIcon4 = "dh_zl";
export var servicesToolkitSection__serviceToolkitIcon5 = "dh_zm";
export var servicesToolkitSection__serviceToolkitIcon6 = "dh_zn";
export var servicesToolkitSection__serviceToolkitIcon7 = "dh_zp";
export var servicesToolkitSection__serviceToolkitIcon8 = "dh_zq";
export var servicesToolkitSection__serviceToolkitIcon9 = "dh_zr";
export var servicesToolkitSection__sprite = "dh_zg";
export var servicesToolkitSection__title = "dh_zG";